// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-antv-gatsby-theme-antv-site-pages-404-tsx": () => import("./../../../node_modules/@antv/gatsby-theme-antv/site/pages/404.tsx" /* webpackChunkName: "component---node-modules-antv-gatsby-theme-antv-site-pages-404-tsx" */),
  "component---node-modules-antv-gatsby-theme-antv-site-pages-index-tsx": () => import("./../../../node_modules/@antv/gatsby-theme-antv/site/pages/index.tsx" /* webpackChunkName: "component---node-modules-antv-gatsby-theme-antv-site-pages-index-tsx" */),
  "component---node-modules-antv-gatsby-theme-antv-site-templates-document-tsx": () => import("./../../../node_modules/@antv/gatsby-theme-antv/site/templates/document.tsx" /* webpackChunkName: "component---node-modules-antv-gatsby-theme-antv-site-templates-document-tsx" */),
  "component---node-modules-antv-gatsby-theme-antv-site-templates-example-tsx": () => import("./../../../node_modules/@antv/gatsby-theme-antv/site/templates/example.tsx" /* webpackChunkName: "component---node-modules-antv-gatsby-theme-antv-site-templates-example-tsx" */),
  "component---site-pages-index-en-tsx": () => import("./../../../site/pages/index.en.tsx" /* webpackChunkName: "component---site-pages-index-en-tsx" */),
  "component---site-pages-index-zh-tsx": () => import("./../../../site/pages/index.zh.tsx" /* webpackChunkName: "component---site-pages-index-zh-tsx" */)
}

